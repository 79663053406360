<template>
    <div>
        <div class="flex items-center justify-between bg-white border rounded-t-sm px-4 shadow-lg">
            <div>
                <stepper>
                    <li><button>DRAFT</button></li>
                    <li><button :class="{'active':form.status == 'In Progress'}">IN PROGRESS</button></li>
                    <li><button :class="{'active':form.status == 'Validated'}">VALIDATED</button></li>
                </stepper>
            </div>
            <div v-if="form.status == 'In Progress'">
                <button @click="onValidate" class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600 relative" :disabled="isDisabled">
                    <div v-if="isDisabled" class="absolute bg-teal-400 bg-opacity-70 w-full h-full inset-0 rounded-sm flex items-center justify-center">
                        <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                    VALIDATE
                </button>
            </div>
        </div>

        <div class="p-5">
            <div class="bg-white border rounded-t-sm">
                <div class="flex justify-between items-center">
                    <div class="flex whitespace-nowrap cursor-pointer relative font-medium">
                        <div @click="onTab('overview')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='overview'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">OVERVIEW</div>
                        <div @click="onTab('task')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='task'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">TASK & NOTES <span class="py-0.5 px-1 rounded-sm bg-gray-800 text-gray-50 ml-2 text-xs min-w-min w-5 inline-block text-center">{{task}}</span></div>
                    </div>
                </div>
            </div>

            <!-- OVERVIEW -->
            <div v-show="tab=='overview'" class="animated fadeIn faster">
                <div class="grid grid-cols-9 p-5 divide-x bg-white border border-t-0 border-b-0">
                    <div class="col-span-5 pr-5">
                        <div class="grid grid-cols-2 leading-none mb-5">
                            <div class="col-span-1">
                                <div class="text-lg font-medium">{{form.stockOpnameNo}}</div>
                                <div class="text-gray-400 text-xs">STOCK OPNAME #</div>
                            </div>
                            <div class="col-span-1 leading-none">
                                <div class="text-lg">{{getDate(form.createdAt)}}</div>
                                <div class="text-gray-400 text-xs">DATE CREATED</div>
                            </div>
                        </div>
                        <div>
                            <label for="">WAREHOUSE</label>
                            <div class="font-medium">{{form.warehouse}}</div>
                        </div>
                    </div>

                    <div class="col-span-4 pl-4">
                        <div class="grid grid-cols-2 gap-5 mb-6">
                            <div class="col-span-1">
                                <div>
                                    <label for="">VALIDATE</label>
                                    <div v-if="form.validated">{{getDate(form.validated)}}</div>
                                    <div v-else>--</div>
                                </div>
                            </div>
                            <div class="col-span-1">
                                <div>
                                    <label for="">USER</label>
                                    <div>{{form.user}}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label for="">REMARKS</label>
                            <div v-if="form.remarks">{{form.remarks}}</div>
                            <div v-else>--</div>
                        </div>
                    </div>
                </div>
                <add-items v-model="form.items" @onEdit="onEdit" :warehouseId="form.warehouseId" :isDisabled="status"></add-items>
            </div>
            <!-- TASK & NOTE -->
            <div v-show="tab == 'task'" class="bg-white border border-t-0 rounded-b-sm animated fadeIn faster">
                <task-manager v-model="task" :documentName="form.stockOpnameNo"></task-manager>   
            </div>
        </div>
    </div>
</template>
<script>
import stepper from '../../../components/stepper.vue'
import addItems from '../../../components/additemsStockOpname.vue'
import axios from 'axios'
import {moment} from '../../../moment'
import BreadcrumbsManager from '../../../mixins/breadcrumbsManager.vue'
import {EventBus} from '../../../event-bus'
import TaskManager from '../../../components/taskManager.vue'
export default {
    components: {stepper, addItems, TaskManager},
    mixins:[BreadcrumbsManager],
    data () {
        return {
            isEdited: false,
            isDisabled: false,
            documentName: '',
            task: 0,
            tab: 'overview',
            form: {
                warehouseId: '',
                remarks: '',
                items: [],
                userId: ''
            },
        }
    },
    mounted () {
        this.setButtonText('EDIT')
        this.setDiscard(false)
        this.getData(true)
        EventBus.$on('onClick', this.onEdit)
        EventBus.$on('onCancel', this.onDiscard)
        this.getData(true)
    },
    destroyed () {
        if(this.isEdited) {
            EventBus.$off('onClick', this.onSave)
        } else {
            EventBus.$off('onClick', this.onEdit)
        }
        EventBus.$off('onCancel', this.onDiscard)
    },
    computed: {
        status () {
            if(this.form.status == 'Validated') {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        getData (v) {
            axios.get('/stockopname/edit/'+this.$route.params.id)
            .then(res => {
                this.form = res.data
                if(v) {
                    this.pushBreadcrumb({
                        text: res.data.stockOpnameNo
                    })
                }
            })
        },
        onEdit () {
            this.isEdited = true
        },
        onSave () {
            axios.put('/stockopname/update/'+this.$route.params.id, this.form)
            .then(() => {
                this.onDiscard()
            })
        },
        onTab (i) {
            this.tab = i
        },
        onValidate () {
            axios.put('/stockopname/validate/'+this.$route.params.id, this.form)
            .then((res) => {
                this.popBreadcrumb()
                this.pushBreadcrumb({
                    text: res.data.stockOpnameNo
                })
                this.getData(false)
            })
        },
        getDate (d) {
            return moment(d)
        },
        onDiscard () {
            this.isEdited = false
            this.getData(false)
        },
    },
    watch: {
        isEdited (val) {
            if(val) {
                this.setButtonText('SAVE')
                this.setDiscard(true)
                this.setDiscardText('DISCARD')
                EventBus.$off('onClick', this.onEdit)
                EventBus.$on('onClick', this.onSave)
            } else {
                this.setButtonText('EDIT')
                this.setDiscard(false)
                this.setDiscardText()
                EventBus.$off('onClick', this.onSave)
                EventBus.$on('onClick', this.onEdit)
            }
        }
    },
    beforRouteLeave (to, from , next) {
        this.popBreadcrumb()
        next()
    } 
}
</script>